import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
const CardMessage = ({ data }) => {
    return (
        <div className="w-100 p-5 text-center card-text-message">
            {data?.texts.map((text, i) => (
                <p
                    key={i}
                    dangerouslySetInnerHTML={{
                        __html: text.content,
                    }}
                />
            ))}
        </div>
    );
};

CardMessage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default CardMessage;
