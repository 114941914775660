/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState } from "react";
import PropTypes from "prop-types";
import ProductsModal from "@components/modal-products";
import { getImage } from "gatsby-plugin-image";

const ProductCard = ({ product }) => {
    const [show, setShow] = useState(false);

    const getGatsbyImgUrl = (item) => {
        return getImage(item.images[0].src).images.fallback.src;
    };
    return (
        <>
            <li
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="100"
                data-aos-once="true"
                title={product.title}
                onClick={() => setShow(true)}
                onKeyPress={() => setShow(true)}
                style={{
                    backgroundImage: `url(${getGatsbyImgUrl(product)})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    minWidth: "264px",
                }}
            />
            <ProductsModal show={show} setShow={setShow} product={product} />
        </>
    );
};

ProductCard.propTypes = {
    product: PropTypes.object,
};

export default ProductCard;
