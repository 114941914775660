import * as React from "react";

const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width={100}
        height={100}
        fillRule="evenodd"
        {...props}
    >
        <path d="m13 5 3 9v6h2v-6l3-9h-2l-2 6-2-6Zm11 4c-1.066 0-1.59.168-2.242.703-.625.527-.797.934-.758 2.297v5c0 .996.164 1.652.766 2.234.625.582 1.214.766 2.234.766 1.066 0 1.648-.184 2.25-.766.625-.558.75-1.238.75-2.234v-5c0-.883-.156-1.719-.762-2.277C25.613 9.148 24.968 9 24 9Zm5 0v9c0 .973.98 2 2 2s1.559-.512 2-1v1h2V9h-2v8c-.012.684-.816 1-1 1-.207 0-1-.043-1-1V9Zm-5 2c.3 0 1-.004 1 1v5c0 .969-.676 1-1 1-.3 0-1-.012-1-1v-5c0-.816.434-1 1-1ZM10 22c-3.594 0-6 2.383-6 6v9.5c0 3.617 2.406 6.5 6 6.5h30c3.594 0 6-2.383 6-6V28c0-3.617-2.406-6-6-6Zm2 4h6v2h-2v12h-2V28h-2Zm14 0h2v4c.23-.36.574-.645.902-.805a2.13 2.13 0 0 1 .973-.258c.648 0 1.156.235 1.504.672.348.442.621 1.028.621 1.891v6c0 .742-.25 1.203-.578 1.598-.32.394-.8.894-1.422.902-1.05.012-1.613-.55-2-1v1h-2Zm-8 3h2v8c0 .23.27 1.008 1 1 .813-.008.82-.766 1-1v-8h2v11h-2v-1c-.371.438-.563.574-.98.781-.415.235-.836.219-1.227.219-.484 0-1.035-.438-1.293-.766-.23-.3-.5-.609-.5-1.234Zm18.2 0c.948 0 1.616.203 2.12.734.516.532.68 1.153.68 2.153V35h-4v1.547c0 .558.074.914.219 1.125.136.23.414.332.781.328.406-.004.664-.086.8-.27.141-.164.2-.628.2-1.23V36h2v.594c0 1.09-.086 1.902-.625 2.433-.508.559-1.3.817-2.34.817-.95 0-1.691-.282-2.222-.86-.532-.578-.81-1.37-.81-2.39v-4.707c0-.907.317-1.578.9-2.176.468-.48 1.347-.711 2.296-.711ZM29 30.5c-.55 0-.992.496-1 1v6c.008.29.45.5 1 .5s1-.426 1-.977V32c0-1-.45-1.5-1-1.5Zm7 .5c-.55 0-.992.465-1 1v1h2v-1c0-.613-.45-1-1-1Z" />
    </svg>
);

export default SvgComponent;
