import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { X } from "react-feather";
import Image from "@ui/image";
import Button from "@ui/button";

import InfoImg from "./InfoImg";
import YTImg from "./YTImg";

import YTLogoImg from "../../data/images/estradata/yt-logo.png";
import YTLogoImgWhite from "../../data/images/estradata/yt-logo-white.png";
import "./styles.css";

const ProductsModal = ({ show, setShow, product }) => {
    const [infoHovered, setInfoHovered] = useState(false);
    const [youtubeHovered, setYoutubeHovered] = useState(false);

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            centered={true}
        >
            <Modal.Header>
                <Modal.Title
                    id="example-custom-modal-styling-title"
                    className="sr-only"
                >
                    Product Modal
                </Modal.Title>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setShow(false)}
                >
                    <span aria-hidden="true">
                        <X />
                    </span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="portfolio-popup-thumbnail">
                            {product.images?.[0]?.src && (
                                <div className="image">
                                    <Image
                                        src={product.images[0].src}
                                        alt={
                                            product.images[0]?.alt ||
                                            product.title
                                        }
                                        className="w-100"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="text-content">
                            <h3>
                                <span>{product.subtitle}</span> {product.title}
                            </h3>
                            {product.texts?.map((text, i) => (
                                <p
                                    className={
                                        i !== product.texts.length - 1
                                            ? "mb--30"
                                            : ""
                                    }
                                    key={i}
                                >
                                    {text.content}
                                </p>
                            ))}
                            <div className="button-group mt--20 d-flex">
                                <Button
                                    path={product.stringList?.[0] || ""}
                                    className="thumbs-icon info-button"
                                >
                                    <span>Me interesa</span>
                                    <InfoImg className="ml-2 button-icon" />
                                </Button>
                                {product.stringList?.[1] && (
                                    <Button
                                        path={product.stringList?.[1] || ""}
                                        className="info-button"
                                    >
                                        <span>Ver en</span>
                                        <YTImg className="ml-2 button-icon" />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

ProductsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
};

export default ProductsModal;
