/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import "./styles.css";
import VideoLanding from "./video-landing-2.mp4";

const VideoArea = () => (
    <div>
        <video
            className="video"
            autoPlay="autoplay"
            autoPlay
            muted="muted"
            loop
            playsinline="playsinline"
        >
            <source src={VideoLanding} type="video/mp4" />
            Tu navegador no admite el elemento <code>video</code>.
        </video>
    </div>
);

export default VideoArea;
