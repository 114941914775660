import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import HeroArea from "@containers/hero/layout-02";
import { default as ProductsArea } from "@containers/skill/layout-03";
import AboutArea from "@containers/about/layout-02";
import { default as SolutionArea } from "@containers/client/layout-03";
import SupportArea from "@containers/support";
import Footer from "@layout/footer/layout-02";
import CardMessage from "@components/black-card-message";
import VideoArea from "@containers/video";
const IndexPage = ({ data }) => {
    const content = normalizedData(data?.homePage?.content || []);
    return (
        <Layout pageTitle="Home" className="white-version home-classic">
            <Header
                data={{
                    ...data.header,
                    ...data.navigation,
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="main-page-wrapper">
                <HeroArea data={content["hero-section"]} />
                <CardMessage data={content["card-message-section"]} />
                <AboutArea data={content["about-section"]} />
                <SolutionArea
                    data={content["solutions-section"]}
                    id="solutions"
                />
                <ProductsArea
                    data={content["products-section"]}
                    id="products"
                />
                {/* Video */}
                <VideoArea id="video" />

                <SupportArea data={content["support-section"]} id="support" />
            </main>
            <Footer
                className="section-separator"
                data={{
                    ...data.footer,
                    socials: data.site.siteMetadata.socials,
                }}
            />
        </Layout>
    );
};

export const query = graphql`
    query DefaultPageQuery {
        site {
            ...Site
        }
        header: general(section: { eq: "header-5-white" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-1" }) {
            menu {
                ...Menu01
            }
        }
        footer: general(section: { eq: "footer-2-white" }) {
            ...Footer02
        }
        homePage(title: { eq: "fashion-designer-home-white" }) {
            content {
                ...Content02
            }
        }
        allArticle(limit: 3) {
            nodes {
                ...Article
            }
        }
    }
`;

IndexPage.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                socials: PropTypes.arrayOf(PropTypes.shape({})),
                contact: PropTypes.shape({
                    phone: PropTypes.string,
                    email: PropTypes.string,
                }),
                getform_url: PropTypes.string,
            }),
        }),
        homePage: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        navigation: PropTypes.shape({}),
        header: PropTypes.shape({}),
        footer: PropTypes.shape({}),
    }),
};

export default IndexPage;
