import React from "react";
import PropTypes from "prop-types";
import { ItemType, SectionTitleType } from "@utils/types";
import SectionTitle from "@components/section-title";
import Button from "@ui/button";
import "./styles.css";

const SupportArea = ({ data, id }) => {
    const supportPageUrl = data.texts[0].content;

    return (
        <div id={id} className="rn-about-area rn-section-gap section-separator">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 p-5">
                        {data?.section_title && (
                            <SectionTitle
                                data-aos="fade-up"
                                data-aos-duration="500"
                                data-aos-delay="100"
                                data-aos-once="true"
                                className="text-center"
                                {...data.section_title}
                            />
                        )}

                        <div className="d-flex justify-content-center w-100 mt-5">
                            <Button
                                path={supportPageUrl}
                                className="btn-support"
                            >
                                <div className="btn-support-img"></div>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

SupportArea.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
        texts: PropTypes.arrayOf(PropTypes.object),
    }),
};

export default SupportArea;
